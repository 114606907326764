import React, { useState, useEffect, useContext } from "react";
import { withStyles } from '@material-ui/core/styles';
import configData from '../../../config/config.json';
import Dialog from '@material-ui/core/Dialog';
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Carousel from "react-material-ui-carousel";
import CircularProgress from "@material-ui/core/CircularProgress";
import  LocationOnIcon  from "@material-ui/icons/LocationOn";
import StarIcon from "@material-ui/icons/Star";
import ThumbUp from "@material-ui/icons/ThumbUp";

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
      backgroundColor:'#eee'
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const API_ENDPOINT = configData.API_ENDPOINT;
//const [imageLoading, setImageLoading] = useState(false);
//const [counter, setCounter] = useState(1);

class SimpleDialog extends React.Component {

    //{ onClose, properties, packageUid, originalPropertyUID, originalPropertyInventoryUID, open, packagePricePP} = props;

    constructor(props){
        super(props);

        console.log('Constructor')
        this.state = {
            imageLoading: false,
            counters: []
        };

        const propertyImagesArray = [];

        this.props.properties.forEach((property, index) => {
            propertyImagesArray.push(1);
        });

        this.setState({
            counters: propertyImagesArray
        },()=>{
            console.log(this.state.counters)
        });
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.properties !== prevProps.properties) {
            console.log('Change Property Popup')
            const propertyImagesArray = [];

            this.props.properties.forEach((property, index) => {
                propertyImagesArray.push(1);
            });

            this.setState({
                counters: propertyImagesArray
            });
        }
    }


    handleClose = () => {
        this.props.onClose();
    };

    changeProperty = async (e, propertyInventoryUID) => {
        e.preventDefault();

        // Use the propertyInventoryID to make an axios call to:
        console.log(`changePropertyBtn`)
        let url = `${API_ENDPOINT}/search/packages/${this.props.packageUid}/properties/${propertyInventoryUID}`

        await axios.get(
            url,
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true  
            }
          ).then(res=>{

            if (!res.data || res.data=='')  {
                console.error('COULD NOT FIND PROPERTY INVENTORY UID')
                alert('שגיאה')
                return
            }
            
            let redirect_url = window.location.href;  
            console.log(`old url: ${redirect_url}`)
            redirect_url = redirect_url.replace(this.props.packageUid, res.data)
            console.log(`new url: ${redirect_url}`)

            window.location.href = redirect_url;
          }).catch(err=>{
            console.log(err)
            alert('שגיאה')
          });

          //alert(resPackage.data)

    }

    handleImageChange = (propertyIndex, index) => {
        const arr = this.state.counters;
        arr[propertyIndex] = index + 1;
        this.setState({
            imageLoading:true,
            counters: arr
        });
    };
      
    render() {
        // Search the properties array for a record whose property?.inventory_uid == originalPropertyInventoryUID and place it as the first item in the array
        const property = this.props.properties.find(property => property?.uid == this.props.originalPropertyUID)
        
        // remove the property of the existing package
        let propertiesArray =  this.props.properties.filter(property => property?.uid != this.props.originalPropertyUID);

        // add this property property as the first item in the properties array
        if (property)
            propertiesArray.unshift(property);

        // if we're in the change meal plan modal - cleanup the array (because we only want to show the existing hotel)
        if (this.props.changeMealPlanOnly) {
            propertiesArray = this.props.properties.filter(property => property?.uid == this.props.originalPropertyUID)
        } 

        return (
                <Dialog style={{maxWidth:'650px', margin:'0 auto'}} fullScreen aria-labelledby="simple-dialog-title" open={this.props.open} scroll="paper" id="change_property" onClose={this.handleClose}>
                    <AppBar style={{ position: 'relative', textAlign:'center'}}>
                            <Toolbar style={{background:'#124b71'}} tabIndex={0}>
                                <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h5" style={{flex: 1}}>
                                    {this.props?.changeMealPlanOnly?'בחירת בסיס אירוח':'החלפת מלון'}
                                </Typography>
                                <Box component="span" m={2}></Box>
                            </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {this.props.properties && this.props.properties.length > 1 ? (
                            <div className='ugly modalBody'  >
                
                                <Grid container>
                                    <Grid item className='text-center' xs={12}>
                                        {propertiesArray.map((property,propertyIndex) => {

                                            
                                        

                                            let propertyUID = property.uid;
                                            let propertyName = property.name;
                                            //let propertyCityName = property?.city_name;
                                            let propertyAreaName = property.area_name;
                                            let propertyDescription = property?.description;
                                            let propertyAddress = property.address;
                                            let propertyCityName = this.props?.cityName;
                                            let propertyStars = property.stars;
                                            let propertyRating = property.rating;
                                            let propertyReviewsCount = property.reviews_count;
                                            let propertyDistanceFromCenter = property.distance_from_center;
                                            let propertyLAT = property.lat;
                                            let propertyLNG = property.lng;
                                            let propertyCheckinTime = property.checkin_time;
                                            let propertyCheckoutTime = property.checkout_time;
                                            let propertyHasPool = property.has_pool;
                                            let propertyHasCasino = property.has_casino;
                                            let propertyHasSpa = property.has_spa;
                                            let propertyHasIndoorPool = property.has_indoor_pool;
                                            let propertyHasSkiDoor = property.has_ski_door;
                                            let propertyHasWaterPark = property.has_water_park;
                                            let propertyIsFamilyFriendly = property.is_family_friendly;
                                            let propertyIsFavorable = property.is_favorable;
                                            let propertyIsKosher = property.is_kosher;
                                            let propertyIsNearChabad = property.is_near_chabad;
                                            let propertyIsLuxury = property.is_luxury;
                                            let propertyIsRomantic = property.is_romantic;
                                            let propertyPhotos = property.photos;
                                            let propertyInventory = property.inventory;
                                            let ratingArr = [1, 2, 3, 4, 5, 6];

                                            
                                            /*
                                            const isOriginalProperty = originalPropertyInventoryUID == property?.inventory_uid;
                                            if (isOriginalProperty)
                                                priceChangeCaption = 'הטיסה הכלולה בחופשה זו';
                                            */
                                            const isOriginalProperty =false;
                                            let first = 0;
                                            
                                            return  (
                                            <>
                                                <div style={this.props.changeMealPlanOnly?{display:'none'}:{display:'block'}}>
                                                    {propertyIndex==0?(<h3 style={{fontWeight:700, paddingBottom:0, textAlign:'right'}}>המלון הכלול בחופשה</h3>):propertyIndex==1?(<h3 style={{fontWeight:700, paddingBottom:0, textAlign:'right'}}>מלונות נוספים</h3>):''}
                                                </div>
                                                <div className="card" key={'item'+propertyIndex} style={isOriginalProperty?{boxShadow:'3px 3px 3px rgba(0, 0, 0, 0.1)', border:'2px solid #666',borderRadius:'10px',marginBottom:'1em',padding:'0.5em',backgroundColor:'#fff'}:{border:'1px solid #ccc',borderRadius:'10px',marginBottom:'1em',padding:'0.5em',backgroundColor:'#fff', boxShadow:'3px 3px 3px rgba(0, 0, 0, 0.1)'}} onClick={propertyIndex==0?this.handleClose:null}>
                                                    
                                                    <div>
                                                        <div className="content" style={this.props?.changeMealPlanOnly?{display:'none'}:{padding:'0 .5em', fontSize:'.9em',fontWeight:600, color:'#333', textAlign:'right'}}>
                                                                <center>
                                                                    <h3 style={{direction:'ltr',margin:0, padding:'.5em 0 0 0', fontWeight:800}}>{propertyName}</h3>
                                                                    <p style={{direction:'ltr',marginBottom:'1em'}}>{propertyAddress} {propertyAreaName} {propertyCityName}</p>
                                                                </center>
                                                                <div className="carrousalBody" style={{display:'inline-block',background:'#ddd', width:'100%', position:'inherit'}} dir="rtl">
                                                                    <Carousel
                                                                        dir="rtl"
                                                                        onChange={(index) => this.handleImageChange(propertyIndex, index)}
                                                                        navButtonsProps={{
                                                                            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                                                            style: {
                                                                                color: "#666666",
                                                                                backgroundColor: "#ffffffcf",
                                                                                borderRadius: 0,
                                                                                margin: 0,

                                                                            },
                                                                        }}
                                                                        indicatorIconButtonProps={{
                                                                            style: {
                                                                                display: "none", // 3
                                                                            },
                                                                        }}
                                                                        stopAutoPlayOnHover={true}
                                                                        autoPlay={false}
                                                                        swipe={true}
                                                                        
                                                                        animation="slide"
                                                                        navButtonsAlwaysVisible="true"
                                                                        NextIcon={<div title="הצג תמונה הבאה" style={{fontWeight:800}}>&#8249;</div>}
                                                                        PrevIcon={<div title="הצג תמונה קודמת" style={{fontWeight:800}}>&#8250;</div>}
                                                                    >
                                                                        {propertyPhotos.map((item, i) => (
                                                                            <div className="carrousalDiv text-center" key={'carrousel_item'+i}>
                                                                                <div className="carousalImage" style={{background:`url(${"https://cdn.cookiecrumbz.com/images/properties/600/"+item.filename})`}} title="גלריית תמונות של המלון"></div>
                                                                            </div>
                                                                        ))}
                                                                    </Carousel>
                                                                    <span style={{margin:'-45px 10px'}} className="imgCounter" title={`מציג ${propertyPhotos.length} תמונות`}>
                                                                        {this.state.counters[propertyIndex]}/{propertyPhotos.length}
                                                                    </span>
                                                                </div>
                                                                

                                                                <div style={{display:'flex', textAlign:'center', justifyContent:'space-between', padding:'0 1em', margin:'.5em 0 1.5em', fontSize:'.9em'}}>
                                                                    
                                                                    <div style={{textAlign:'center', marginTop:'.35em'}}>
                                                                        <ThumbUp title="דירוג גולשים" style={{ fontSize: "22px", color: "#555555" }} /><br />
                                                                        {propertyRating?propertyRating:4}/5 <br /> דירוג גולשים
                                                                    </div>
                                                                    
                                                                    <div style={{textAlign:'center', marginTop:'.35em'}}>
                                                                        <LocationOnIcon title="צפה במפה" style={{ fontSize: "24px", color: "#555555" }} /><br />
                                                                        <label style={{fontSize:0,color:'#fff'}}>{`המלון מרוחק `} {propertyDistanceFromCenter<1?propertyDistanceFromCenter*1000+' מטרים':(propertyDistanceFromCenter*1).toFixed(1) + ' קילומטרים'} {`מהמרכז`}</label>
                                                                        {propertyDistanceFromCenter<1?propertyDistanceFromCenter*1000+' מטר':(propertyDistanceFromCenter*1).toFixed(1) + ' ק"מ'} <br /> מהמרכז 
                                                                    </div>

                                                                    <div>
                                                                            {/* <Rating disabled name="size-small" defaultValue={stars} /> */}
                                                                            {ratingArr?.map((item, index) => {
                                                                                if (index < propertyStars) {
                                                                                return (
                                                                                    <StarIcon key={index+'stars'}
                                                                                    style={{ color: "#ffc772", height: "28px" }}
                                                                                    />
                                                                                );
                                                                                }
                                                                            })}
                                                                            <br />
                                                                            <label>{propertyStars} כוכבים</label>
                                                                    </div>
                                                                </div>

                                                                <p style={{fontSize:'1.2em', margin:0}}>{propertyHasPool?'בריכה':''}
                                                                    {propertyHasCasino?'קזינו':''} 
                                                                    {propertyHasSpa?'ספא':''} 
                                                                    {propertyHasIndoorPool?'בריכה מקורה':''} 
                                                                    {propertyHasSkiDoor?'דלת סקי':''} 
                                                                    {propertyHasWaterPark?'פארק מים':''} 
                                                                    {propertyIsFamilyFriendly?'ידידותי למשפחות':''} 
                                                                    {propertyIsFavorable?'מועדף':''} 
                                                                    {propertyIsKosher?'כשר':''} 
                                                                    {propertyIsNearChabad?'קרוב לחב"ד':''} 
                                                                    {propertyIsLuxury?'יוקרתי':''} 
                                                                    {propertyIsRomantic?'רומנטי':''}</p>
                                                        </div>
                                                        <div>
                                                            {propertyInventory.map((inventory, index) => {
                                                                
                                                                let inventoryPrice = inventory.price;
                                                                let inventoryPricePP = inventory.price_pp;
                                                                let inventoryUID = inventory.inventory_uid;
                                                                let inventoryMealPlanCode = inventory.meal_plan_code;
                                                                let inventoryMealPlanName = 'לינה בלבד';

                                                                let priceDeltaPP = inventoryPricePP-this.props.packagePricePP;
                                                                if (priceDeltaPP > 0)
                                                                    priceDeltaPP = Math.ceil(priceDeltaPP)
                                                                else
                                                                    priceDeltaPP = Math.floor(priceDeltaPP)

                                                                let priceChangeCaption = priceDeltaPP>0 ? 
                                                                    `תוספת $${priceDeltaPP} לנוסע`:
                                                                    `חסכון $${priceDeltaPP*-1} לנוסע`;
                                                                if (priceDeltaPP == 0)
                                                                    priceChangeCaption = ''

                                                                switch(inventoryMealPlanCode){
                                                                        case 'BB':inventoryMealPlanName ='ארוחת בוקר';break;
                                                                        case 'HB':inventoryMealPlanName ='חצי פנסיון';break;
                                                                        case 'FB':inventoryMealPlanName ='פנסיון מלא';break;
                                                                        case 'AI':inventoryMealPlanName ='הכל כלול';break;
                                                                        case 'RO':inventoryMealPlanName ='לינה בלבד';break;
                                                                        default:inventoryMealPlanName ='לינה בלבד';break;
                                                                    }
                                                                let inventoryCheckinDate = inventory.checkin_date;
                                                                let inventoryCheckoutDate = inventory.checkout_date;


                                                                return (
                                                                    <>
                                                                    <hr />
                                                                    <Grid container alignItems="stretch" justifyContent="space-evenly" alignContent="center" tabIndex={4} style={{padding:'.5em .5em .5em 0', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                        <Grid item xs={4}>
                                                                            <div style={{fontSize:'.9em', fontWeight:600, color:'#333', height: '100%', textAlign:'right', paddingRight:'.5em'}}>
                                                                                {inventoryMealPlanName}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={5}>
                                                                            <div style={{fontSize:'.9em', fontWeight:600, color:'#333', height: '100%', textAlign:'right', direction:'ltr'}}>
                                                                                {inventory.uid == this.props.originalPropertyInventoryUIDs ? 
                                                                                        (<>
                                                                                            <p style={{padding:'.65em 0', fontWeight:700}}>כלול/ה במחיר</p>
                                                                                        </>) : 
                                                                                        (<>
                                                                                            <h3 style={inventory.uid != this.props.originalPropertyInventoryUIDs && first++==0?{lineHeight:0.9, fontSize:'1.4em', padding:0, margin: 0, fontWeight:800}:{lineHeight:0.9, fontSize:'1.4em', padding:0, margin: 0, fontWeight:700, color:'#555'}}>{priceDeltaPP>0?'+$'+priceDeltaPP:'-$'+Math.abs(priceDeltaPP)}</h3>
                                                                                            <p style={{ color:'#777', lineHeight:1}}>{priceChangeCaption}</p>
                                                                                        </>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <div style={{textAlign:'left', fontSize:'.9em', fontWeight:600, color:'#333', padding:'0 .5em'}}>
                                                                               
                                                                                {inventory.uid == this.props.originalPropertyInventoryUIDs ? (
                                                                                    <p style={{display:'none', textAlign:'center', padding:'0 1em', lineHeight:1, color:'#124b71', fontSize:'.9em'}}><strong>כלול/ה<br />במחיר</strong></p>
                                                                                ) : (
                                                                                    <Button style={{fontSize:'.9em', borderRadius:'5px', backgroundColor:'#124b71', border:'1px solid #aaa', color:'#fff', fontWeight:600}} onClick={e=>this.changeProperty(e, inventory.uid)}>בחירה</Button>
                                                                                )}
                                                                                
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                        </div>
                                                        {/* 
                                                        <div style={(isOriginalProperty)?
                                                            {display:'none'}:
                                                            {display:'block'}}>
                                                            <button style={{backgroundColor:'#1e5881', color:'#fff', borderRadius:'5px', border:'none', padding:'.35em .6em', fontWeight:600}} onClick={e=>changeProperty(e, null)}>החלפת מלון</button>
                                                        </div>
                                                        */}
                                                    </div>
                                                </div>
                                            </>
                                        )})}
                                    </Grid>    
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {propertiesArray.length <= 1 && !this.props?.changeMealPlanOnly? (<div style={{textAlign:'center', padding:'0em 3em'}}><h3 align="center" style={{color:'#555', fontWeight:700}}>
                                            לא נמצאו מלונות נוספים ליעד זה בתאריך המבוקש</h3></div>) : ''}
                                    </Grid>
                                </Grid>
                            </div>
                        ):(<p style={{marginTop:'2em'}}><CircularProgress size="2.5rem" style={{color:'#1e5881'}}></CircularProgress></p>)}
                    </DialogContent>
        
                </Dialog>
        );
    }

 
} export default SimpleDialog